.Modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.Modal:after {
  content: '';
  background: black;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
}

.Modal .modal-wrapper {
  background: #fff;
  max-width: 600px;
  height: fit-content;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
  padding-top: 0;
}

.Modal .modal-header {
  text-align: right;
}

.Modal .modal-header .close {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 20px;
  outline: none;
}

.Modal .modal-header .close:before,
.Modal .modal-header .close:after {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #383838;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.Modal .modal-header .close:before {
  transform: rotate(45deg);
}

.Modal .modal-header .close:after {
  transform: rotate(-45deg);
}

.Modal .modal-header h3 {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 24px;
}

.Modal .modal-body {
  text-align: center;
}
