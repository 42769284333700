.App .address-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App .address-wrapper b {
  font-weight: 400;
}

.App .address-wrapper .input-wrapper {
  width: 100%;
}

.App .address-wrapper > div {
  width: 100%;
}

.App .address-wrapper div.checkbox {
  text-align: left;
  max-width: 304px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.App .address-wrapper div input[type='checkbox'] {
  width: auto;
  margin-right: 6px;
  outline: none;
}

.App strong {
  font-weight: 400;
}

.App input,
textarea {
  border: 1px solid #d8d8d8;
  padding: 10px;
  outline: none;
  font-weight: 100;
}

.App input::placeholder,
textarea::placeholder {
  opacity: 0.5;
}

.App input:focus,
textarea.abi:focus {
  outline: none;
  border-color: #909090;
}

.App input {
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
}

.App textarea.abi {
  width: calc(100% - 40px);
  height: 200px;
  max-width: 1024px;
}

.App .error {
  color: #ff4b4b;
  font-size: 20px;
}

.App .error a {
  margin-left: 5px;
  color: inherit;
}

.App .tabs {
  margin: 30px auto;
  display: flex;
  align-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.App .tabs div {
  border-right: 1px solid #383838;
  display: flex;
  align-items: center;
  position: relative;
}

.App .tabs div:last-child {
  border: none;
}

.App .tabs div input {
  font-size: 12px;
  left: 125px;
  width: 105px;
}

.App .tabs a {
  cursor: pointer;
  padding: 0 20px;
  text-decoration: underline;
  color: #3498db;
  font-size: 22px;
}

.App .tabs a.active,
.App .tabs a:hover {
  text-decoration: none;
  color: initial;
}

.App .search {
  margin-bottom: 40px;
}

.App .search input {
  max-width: 400px;
}

.App .results {
  text-align: left;
  margin-bottom: 60px;
}

.App .results .no-results {
  margin-bottom: 120px;
  opacity: 0.5;
  text-align: center;
}

.App .results .result {
  border-bottom: 1px solid #e6e6e6;
}

.App .results .result p:first-child {
  color: #3498db;
  margin-bottom: 10px;
  font-size: 15px;
  word-break: break-all;
}

.App .results .result p + p + p {
  color: #828282;
  margin-top: 0;
  font-size: 12px;
}

.result .param-indexed {
  color: #e13636;
}

.result .param-name {
  color: #e18036;
}

.result .original {
  font-size: 68%;
}

.result .original span {
  display: block;
  margin-left: 10px;
}

.result .original span:last-child {
  margin-left: 0;
}

.result textarea {
  color: #828282;
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  padding: 0;
  resize: none;
  vertical-align: middle;
  height: auto;
  width: 100%;
  font-size: 12px;
  margin-top: -1px;
}

.App .results .result p + p + p button,
.copy-button {
  border: none;
  font-style: italic;
  cursor: pointer;
  outline: none;
  float: none;
  margin-top: 0;
  width: 100%;
  text-align: left;
  padding: 0;
  display: none;
}

.App .source-code {
  margin-bottom: 60px;
}

.App .source-code .react-monaco-editor-container {
  text-align: left;
}

@media (min-width: 620px) {
  .App .results .result p + p + p {
    font-size: 12px;
  }

  .result textarea {
    width: 480px;
    margin-left: 7px;
    height: 17px;
  }

  .App .results .result p + p + p button,
  .copy-button {
    float: right;
    margin-top: -1px;
    width: auto;
    text-align: right;
    display: block;
  }
}

@media (min-width: 768px) {
  .App .results .result p:first-child {
    font-size: 16px;
  }

  .result .original span {
    display: inline-block;
    margin-left: 0;
  }

  .App .results .result p + p + p {
    font-size: 14px;
  }

  .result textarea {
    height: 18px;
    width: 600px;
    font-size: 14px;
    margin-left: 7px;
  }

  .App .results .result p + p button,
  .copy-button {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .App .wrapper {
    display: flex;
    text-align: center;
  }

  .App .wrapper div {
    width: 50%;
  }

  .App .tabs div input {
    position: absolute;
  }

  .result .original {
    font-size: 85%;
  }

  .App input,
  textarea {
    font-size: 16px;
  }

  .App textarea.abi {
    max-width: 450px;
  }

  .App input {
    max-width: 450px;
  }

  .App .address-wrapper div.checkbox {
    max-width: 456px;
  }
}

@media (min-width: 1200px) {
  .App textarea.abi {
    max-width: 500px;
  }

  .App input {
    max-width: 500px;
  }

  .App .address-wrapper div.checkbox {
    max-width: 506px;
  }
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
