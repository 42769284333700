.Editor {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}

.custom-code {
  text-align: center;
}

.custom-code button {
  font-size: 16px;
  background: #81fffd;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-color: #000;
  border-radius: 3px;
}

.code-wrapper {
  width: 50%;
}

.output-wrapper {
  width: calc(50% - 10px);
}

.actions {
  height: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.actions .col {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.actions .col.left {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.actions .col.right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.actions button {
  background: transparent;
  border: none;
  outline: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 6px 13px;
  margin-bottom: 10px;
  font-size: 12px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.actions .col.left button {
  margin-right: 15px;
}

.actions .col.right button {
  margin-left: 15px;
}

.icon {
  background: none;
  margin-right: 6px;
}

.icon.run::after {
  content: '';
  display: inline-block;
  position: relative;
  top: 1px;
  left: 0;
  border-style: solid;
  border-width: 6px 0px 6px 12px;
  border-color: transparent transparent transparent #63aee3;
}

.icon.hide {
  display: inline-block;
  position: relative;
  top: 1px;
  left: 0;
  border-style: solid;
  border-width: 1px 0px 1px 12px;
  border-color: #63aee3;
}

.icon.reset {
  width: 9px;
  height: 9px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-left-color: transparent;
  position: relative;
  color: #63aee3;
}

.icon.reset:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -5px;
  bottom: -1px;
  border-width: 4px;
  border-style: solid;
  border-left-color: transparent;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.icon.copy {
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  height: 1em;
  position: relative;
  text-indent: -9999px;
  vertical-align: middle;
  width: 1em;
  color: #63aee3;
}

.icon.copy:before {
  border: 0.1rem solid currentColor;
  border-bottom-color: transparent;
  border-radius: 0.1em;
  border-right-color: transparent;
  height: 0.8em;
  width: 0.7em;
}

.icon.copy:after,
.icon.copy:before {
  content: '';
  display: block;
  left: 35%;
  position: absolute;
  top: 35%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-45%, -50%);
}

.icon.copy:after {
  border: 0.1rem solid currentColor;
  border-radius: 0.1em;
  height: 0.8em;
  left: 60%;
  top: 60%;
  width: 0.7em;
}

.no-visible {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.lds-spinner {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
}

.lds-spinner div {
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #3398db;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

form {
  margin-top: 10px;
}

form .input-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 15px;
}

form .input-row label {
  font-size: 14px;
  margin-bottom: 7px;
}

form .input-row input {
  border-radius: 3px;
  font-size: 14px;
  padding: 7px;
  color: #383838;
}

form button {
  padding: 7px;
  background: none;
  font-size: 12px;
  border-radius: 3px;
  margin-bottom: 15px;
}

form button + button {
  margin-left: 15px;
}

.result textarea.data {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.3em;
  width: 100%;
  max-width: 32em;
  word-break: break-all;
  color: #828282;
  height: 100%;
  margin: 20px 0;
  margin-left: 0;
}

form + .data.error {
  color: #ff4b4b;
  max-width: 100%;
  font-size: 14px;
}

.tx-link {
  margin: 20px 0;
  display: block;
  color: #3398db;
  font-size: 14px;
}

button {
  outline: none;
  cursor: pointer;
}

.Modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Modal:after {
  content: '';
  background: black;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
}

.Modal .modal-wrapper {
  background: #fff;
  max-width: 600px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  border-radius: 5px;
  padding-top: 0;
}

.Modal .modal-header {
  text-align: right;
}

.Modal .modal-header .close {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 20px;
  outline: none;
}

.Modal .modal-header .close:before,
.Modal .modal-header .close:after {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #383838;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.Modal .modal-header .close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.Modal .modal-header .close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.Modal .modal-header h3 {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 24px;
}

.Modal .modal-body {
  text-align: center;
}

.MultisigTxInfoModal p {
  font-size: 14px;
  overflow: hidden;
  border: 1px solid black;
  padding: 10px;
  border-radius: 3px;
  background: #f1f1f1;
  margin-bottom: 15px;
  overflow-wrap: anywhere;
  text-align: left;
  max-height: 100px;
  overflow-y: scroll;
}

.MultisigTxInfoModal .modal-body button {
  cursor: pointer;
  padding: 8px 25px;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  letter-spacing: 1px;
  outline: none;
  color: var(--text);
  background: var(--monaco);
  font-style: italic;
  border: 1px solid black;
}

.App .results .result .multisig-txs-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  color: #383838;
}

.App .results .result .multisig-txs-wrapper .confirm-tx {
  font-weight: 600;
  font-size: 16px;
  color: #383838;
  margin-top: 0;
}

.App .results .result .multisig-txs-wrapper .multisig-tx-info {
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 7px;
  font-size: 16px;
  color: #383838;
  text-decoration: underline;
}

.App .address-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.App .address-wrapper b {
  font-weight: 400;
}

.App .address-wrapper .input-wrapper {
  width: 100%;
}

.App .address-wrapper > div {
  width: 100%;
}

.App .address-wrapper div.checkbox {
  text-align: left;
  max-width: 304px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 15px;
}

.App .address-wrapper div input[type='checkbox'] {
  width: auto;
  margin-right: 6px;
  outline: none;
}

.App strong {
  font-weight: 400;
}

.App input,
textarea {
  border: 1px solid #d8d8d8;
  padding: 10px;
  outline: none;
  font-weight: 100;
}

.App input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.5;
}

.App input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  opacity: 0.5;
}

.App input::placeholder,
textarea::placeholder {
  opacity: 0.5;
}

.App input:focus,
textarea.abi:focus {
  outline: none;
  border-color: #909090;
}

.App input {
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
}

.App textarea.abi {
  width: calc(100% - 40px);
  height: 200px;
  max-width: 1024px;
}

.App .error {
  color: #ff4b4b;
  font-size: 20px;
}

.App .error a {
  margin-left: 5px;
  color: inherit;
}

.App .tabs {
  margin: 30px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.App .tabs div {
  border-right: 1px solid #383838;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.App .tabs div:last-child {
  border: none;
}

.App .tabs div input {
  font-size: 12px;
  left: 125px;
  width: 105px;
}

.App .tabs a {
  cursor: pointer;
  padding: 0 20px;
  text-decoration: underline;
  color: #3498db;
  font-size: 22px;
}

.App .tabs a.active,
.App .tabs a:hover {
  text-decoration: none;
  color: #000;
  color: initial;
}

.App .search {
  margin-bottom: 40px;
}

.App .search input {
  max-width: 400px;
}

.App .results {
  text-align: left;
  margin-bottom: 60px;
}

.App .results .no-results {
  margin-bottom: 120px;
  opacity: 0.5;
  text-align: center;
}

.App .results .result {
  border-bottom: 1px solid #e6e6e6;
}

.App .results .result p:first-child {
  color: #3498db;
  margin-bottom: 10px;
  font-size: 15px;
  word-break: break-all;
}

.App .results .result p + p + p {
  color: #828282;
  margin-top: 0;
  font-size: 12px;
}

.result .param-indexed {
  color: #e13636;
}

.result .param-name {
  color: #e18036;
}

.result .original {
  font-size: 68%;
}

.result .original span {
  display: block;
  margin-left: 10px;
}

.result .original span:last-child {
  margin-left: 0;
}

.result textarea {
  color: #828282;
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  padding: 0;
  resize: none;
  vertical-align: middle;
  height: auto;
  width: 100%;
  font-size: 12px;
  margin-top: -1px;
}

.App .results .result p + p + p button,
.copy-button {
  border: none;
  font-style: italic;
  cursor: pointer;
  outline: none;
  float: none;
  margin-top: 0;
  width: 100%;
  text-align: left;
  padding: 0;
  display: none;
}

.App .source-code {
  margin-bottom: 60px;
}

.App .source-code .react-monaco-editor-container {
  text-align: left;
}

@media (min-width: 620px) {
  .App .results .result p + p + p {
    font-size: 12px;
  }

  .result textarea {
    width: 480px;
    margin-left: 7px;
    height: 17px;
  }

  .App .results .result p + p + p button,
  .copy-button {
    float: right;
    margin-top: -1px;
    width: auto;
    text-align: right;
    display: block;
  }
}

@media (min-width: 768px) {
  .App .results .result p:first-child {
    font-size: 16px;
  }

  .result .original span {
    display: inline-block;
    margin-left: 0;
  }

  .App .results .result p + p + p {
    font-size: 14px;
  }

  .result textarea {
    height: 18px;
    width: 600px;
    font-size: 14px;
    margin-left: 7px;
  }

  .App .results .result p + p button,
  .copy-button {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .App .wrapper {
    display: -webkit-flex;
    display: flex;
    text-align: center;
  }

  .App .wrapper div {
    width: 50%;
  }

  .App .tabs div input {
    position: absolute;
  }

  .result .original {
    font-size: 85%;
  }

  .App input,
  textarea {
    font-size: 16px;
  }

  .App textarea.abi {
    max-width: 450px;
  }

  .App input {
    max-width: 450px;
  }

  .App .address-wrapper div.checkbox {
    max-width: 456px;
  }
}

@media (min-width: 1200px) {
  .App textarea.abi {
    max-width: 500px;
  }

  .App input {
    max-width: 500px;
  }

  .App .address-wrapper div.checkbox {
    max-width: 506px;
  }
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.App {
  text-align: center;
  max-width: 1200px;
  width: calc(100% - 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.App .header {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 40px;
  font-size: 14px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 20px;
}

.App .header .Dropdown-root {
  width: 165px;
  text-align: left;
}

.App .header .Dropdown-placeholder {
  color: #14517e;
}

.App .header .Dropdown-control:hover {
  box-shadow: unset;
}

.App .header .Dropdown-control {
  border: none;
  padding: 8px 30px 8px 13px;
  background: transparent;
}

.App .footer {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
  text-align: right;
  background: #1f1f1f;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.App .footer a {
  color: white;
  font-size: 16px;
  text-decoration: none;
  margin-right: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383838;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 400;
}

h2,
h3 {
  font-weight: 100;
}

